import React from 'react';
import { hydrate, render } from "react-dom";
import './styles/main.scss';
import { BrowserRouter } from "react-router-dom";
import App from './App';

const Application = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById("root");
if (rootElement!.hasChildNodes()) {
  hydrate(<Application />, rootElement);
} else {
  render(<Application />, rootElement);
}
